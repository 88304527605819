@media screen and (max-width: 1100px) {
    .secondary-nav-link {
        font-size: .9rem;
        padding: 5px 8px;
    }
    .secondary-nav-link i {
        font-size: .9rem;
    }
    .share-txt {
        font-size: 1rem;
    }
    .clickable-link {
        font-size: .9rem;
    }
    .edit-btns-large{
        display: none;
    }
    .edit-container {
        display: flex !important;
        margin-top: 5px;
    }
}
@media screen and (max-width: 930px) {
    .logo a {
        font-size: 1.5rem;
        transition: all .5s;
    }
    .secondary-menu {
        display: none;
    }
    #menu-icon {
        display: block;
    }
    .secondary-menu-mobile {
        display: block;
    }
}


@media screen and (max-width: 796px) {
    .error-wrapper {
        min-height: 60vh;
        margin-top: 3rem !important;
    }

}

@media screen and (max-width: 575px) {
    .link-container {
        flex-direction: column;
    }
    .logo a {
        font-size: 1.5rem;
        transition: all .5s;
    }
    #home {
        width: 97vw;
        margin: .5rem auto;
        padding: 1.5rem 1rem;
    }

    .controls-large {
        display: none;
    }
    .controls-mobile {
        display: block;
    }
    /* #main-menu-icon {
        display: block;
    } */
    /* .primary-nav {
        display: none;
    } */
    .text-box {
        width: 350px;
        height: 200px;
        margin: 5px;
        padding: 8px 10px;
    }
    .text-container {
        min-height: 450px;
        max-height: 500px;
        overflow-y: scroll;
    }
    .controls, .controls button{
        font-size: 0.9rem;
    }
    .join-room {
        width: 350px;
    }
    .join-btn {
        padding: 8px;
    }
    .join-room label {
        font-size: 1.7rem;
    }
    .join-room p {
        font-size: 1rem;
    }
    .join-room input {
        font-size: 1.2rem;
    }
    .upload-box {
        width: 400px;
    }
    .upload-box p {
        margin-top: 1rem;
    }
    .download-box {
        width: 420px !important;
    }
    .download-group {
        width: 390px !important;
        grid-template-columns: 4fr 1fr;  
    }
    .file-section-divider {
        width: 100%;
    }
    .delete-all-grid {
        width: 400px;
        grid-template-columns: 4fr 2fr;
    }
    .contact-us {
        width: 400px;
        padding: 10px;
    }
    .m-flex-column {
        flex-direction: column;
    }
    .contact-us input {
        width: 100% !important;
        margin: 5px 0;
    }
    .contact-em {
        width: 100% !important;
    }
    .contact-us #mobile {
        margin-right:0;
    }
    
}

@media screen and (max-width: 500px) {
    .link-container {
        flex-direction: column;
    }
    .logo a {
        font-size: 1.5rem;
        transition: all .5s;
    }
    .download-box {
        width: 400px;
    }
    .download-group {
        width: 370px;
        grid-template-columns: 4fr 1fr;  
    }
}

@media screen and (max-width: 796px) {
    /* Hamburger Menu */
}

@media screen and (max-width: 796px) {
    /* Hamburger Menu */
}

