@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --primary: #00ffff;
    --overlay: rgba(0, 0, 0, 0.7);
    --overlay2: rgb(0, 44, 44);
    --overlay3: rgb(0, 44, 44, .6);

    --secondary: white;
    --blk1: #131313;
    --blk2: #1a1a1a;
    --blk3: #333333;

    --light: white;
    --grey: #e6e6e6;

    --success: #198754;
    --green: #006837;
    --lightgreen: #00ff00;
    --lemongreen: #009245;

    --danger: #ff1d25;

    --warning: #fcee21;
    --darkorange: #fbb03b;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background-color: var(--blk3);
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary);
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

* {
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: var(--blk2);
    color: var(--light);
    overflow-x: hidden;
}

header {
    width: 90vw;
    margin: auto;
    margin-top: 1rem;
}

.font-09 {
    font-size: .9rem !important;
}

.logo a {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary);
    transition: all .5s;
}

.logo a:hover {
    color: var(--secondary);
    transition: all .5s;
}

.nav-link {
    padding: 5px 15px;
    letter-spacing: 1px;
    transition: all .5s linear !important;
}

.contact:hover {
    text-decoration: underline;
    color: var(--primary);
}

#home {
    background-color: var(--blk1);
    width: 90vw;
    margin: 1rem auto;
    padding: 2rem;
    border-radius: 1rem;
}

.link-container {
    background-color: var(--overlay2);
}

.link-container a {
    text-decoration: underline;
    margin: 6px 12px;
    letter-spacing: .5px;
    color: var(--warning);
    font-size: .9rem;
    font-family: 'Open Sans'sans-serif;
    transition: all .3s linear;
}

.link-container a:hover {
    color: var(--primary) !important;
}

.link-container a:nth-child(even) {
    color: var(--darkorange);
}

.secondary-nav-link {
    padding: 6px 15px;
    margin: 0 8px;
    background-color: transparent;
    color: var(--light);
    font-size: .95rem;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    letter-spacing: .5px;
}

.secondary-nav-link:hover {
    background-color: var(--blk3);
    color: var(--light);
    transition: all .4s;
}

.secondary-nav-link i {
    font-size: .9rem;
}

.leave-room {
    border-color: var(--danger);
    color: var(--danger);
}

.leave-room:hover {
    color: var(--light);
    background-color: var(--danger);
}

.menu {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
}

.menu li {
    margin-bottom: 1rem !important;
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.menu li:last-child {
    margin-bottom: 0.5rem !important;
}

#menu-icon {
    font-weight: 700;
    padding: 4px 15px;
    cursor: pointer;
}

#main-menu-icon i {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-secondary-menu {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 350px;
    padding: 1rem 0 !important;
    transform: translate(-50%, -50%);
}

.menu-wrapper-p {
    position: absolute;
    top: -200%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    transition: all .5s;
    z-index: 2333333;
}

.menu-wrapper-s {
    position: absolute;
    top: -200%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay);
    transition: all .5s;
}

.mobile-secondary-menu {
    background-color: var(--blk2);
    padding: 3rem 1rem !important;
    border-radius: 12px;
}

.mobile-secondary-menu li {
    width: 80%;
    margin-bottom: 1.5rem;
}

.mobile-secondary-menu li button {
    border: 1px solid var(--light);
    display: block;
    padding: 10px;
    font-size: 1.1rem;
    text-align: center;
    border-radius: 5px;
    transition: all .3s linear;
    box-shadow: 2px 2px 2px var(--primary);
}

/* Secondary Mobile Menu */
.secondary-menu-mobile {
    display: none;
}

.secondary-menu-mobile li button {
    margin-left: 1rem;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid var(--light) !important;
    font-size: 1rem;
    color: var(--light);
    background-color: transparent;
    transition: all .3s linear;
}

.secondary-menu-mobile li button:hover {
    color: var(--light);
    background-color: var(--blk3);
}

.leave-room-mobile {
    transition: all .3s linear;
    background-color: var(--danger) !important;
}

.leave-room-mobile:hover {
    color: var(--danger) !important;
    background-color: var(--blk2) !important;
}

.leave-room-m {
    border-color: var(--danger) !important;
    background-color: var(--danger);
}

.mobile-menu {
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    background-color: var(--primary);
    padding: 2rem 0;
    border-radius: 12px;
}

.mobile-menu li {
    text-align: center;
    width: 80%;
    margin-bottom: 1rem;

}

.mobile-menu li a {
    display: block;
    font-size: 1.1rem;
    padding: 15px 0;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.divider {
    width: 100vw;
    height: 1px;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.mobile-secondary-menu li a {
    color: var(--light);
}

.mobile-menu li:last-child, .mobile-secondary-menu li:last-child {
    margin-bottom: .5rem;
}

#main-menu-icon, #menu-icon {
    display: none;
}

.text-container {
    min-height: 400px;
    max-height: 450px;
    overflow-y: scroll;
}

.text-container::-webkit-scrollbar {
    width: 0px;
}

.text-box {
    width: calc(85vw / 3);
    height: 200px;
    margin: 5px;
    padding: 8px 10px;
}

.text {
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    height: 85%;
    background: transparent;
    font-size: 1rem;
    overflow-y: scroll;
}

.text::-webkit-scrollbar {
    width: 0px;
}

.controls {
    margin-bottom: 5px;
}

.controls button {
    margin-bottom: 3px;
    margin-left: 8px;
    border: 1px solid;
    padding: 3px 7px;
    border-radius: 4px;
    outline: none;
    background: transparent;
    cursor: pointer;
    transition: all .3s linear;
}

.copyText {
    color: var(--success);
}

.copyText:hover {
    color: var(--light);
    background-color: var(--success);
    border-color: var(--success);
}

.pasteText {
    color: var(--warning);
}

.pasteText:hover {
    color: var(--blk1);
    background-color: var(--warning);
    border-color: var(--warning);
}

.clearText {
    color: var(--danger);
}

.clearText:hover {
    color: var(--light);
    background-color: var(--danger);
    border-color: var(--danger);
}

.uppercase, .lowercase {
    color: var(--primary);
    border-color: var(--primary) !important;
}

.uppercase:hover, .lowercase:hover {
    color: var(--blk1) !important;
    background-color: var(--primary);
}

.res {
    color: var(--darkorange) !important;
    border-color: var(--darkorange) !important;
}

.res:hover {
    color: var(--blk1) !important;
    background-color: var(--darkorange) !important;
}

.cew {
    color: var(--lightgreen) !important;
    border-color: var(--lightgreen) !important;
}

.cew:hover {
    color: var(--blk1) !important;
    background-color: var(--lightgreen) !important;
}

.controls-mobile {
    display: none;
}

.controls-mobile button i {
    font-size: 1rem;
}

.edit {
    display: none;
    letter-spacing: 1.5px;
    border: 1px solid var(--light);
    color: var(--light) !important;
    padding: 3px 1rem;
    border-radius: 4px;
}

.edit-container {
    display: none !important;
    margin-top: 5px;
}

.edit-container .cew {
    color: var(--lemongreen) !important;
    border-color: var(--lemongreen) !important;
}

.edit-container .uppercase {
    margin-left: 0;
}

.summary-container {
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.summary-container button {
    border: none;
    outline: none;
    background: transparent;
}

.summary-container button:first-child {
    margin-left: 0;
}

.join-room-wrapper, .upload-wrapper, .download-wrapper {
    position: absolute;
    top: -200%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay);
    transition: all .5s;
}

.join-room {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: var(--blk2);
}

.join-btn {
    width: 100%;
    padding: 3px 15px;
    letter-spacing: 1px;
}

.join-room label {
    display: block;
    letter-spacing: 1px;
}

.join-room p {
    margin-bottom: 5px;
}

.join-room input {
    width: 100%;
    outline: none;
    border: 1px solid var(--light);
    background-color: transparent;
    border-radius: 5px;
    padding: 4px 8px;
}

.close-room {
    position: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 25px;
    top: 20px;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 4px;
    background-color: var(--danger);
    color: var(--light);
    transition: all .3s linear;
    cursor: pointer;
}

.close-room:hover {
    background-color: var(--blk3);
    color: var(--danger);
}

.upload-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 450px;
    background-color: var(--blk1);
    transform: translate(-50%, -50%);
}

.download-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    background-color: var(--blk1);
    transform: translate(-50%, -50%);
}

.upload-btn {
    margin-top: .5rem;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: var(--success);
    border: 1px solid var(--light);
    border-radius: 8px;
    transition: all .3s linear;
    cursor: pointer;
}

.upload-btn:hover {
    background-color: var(--lemongreen);
    color: var(--light);
}

.download-group {
    font-size: 1.1rem;
    width: 450px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: var(--blk2);
}

.download-group:first-child {
    padding: 8px 12px;
}

.download-group:nth-child(odd) {
    background-color: var(--blk3);
}

.delete-all-grid {
    width: 600px;
    display: grid;
    align-self: center;
    grid-template-columns: 6fr 2fr;
}

.download-all {
    padding: 4px 10px;
    font-size: .9rem;
    font-weight: 600;
}

.download-all:hover {
    background-color: var(--grey);
}

.file-property {
    color: var(--darkorange);
}

.file-section-divider {
    height: .1px;
    width: 600px;
    box-shadow: 0 1px 0px var(--secondary);
}

.file-container {
    padding-bottom: 1rem;
    max-height: 65vh !important;
    overflow-y: scroll;
}

.actions button {
    width: 30px;
    height: 30px;
    font-size: 1.1rem;
    border-radius: 4px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .3s linear;
}

.actions button:first-child {
    color: var(--light);
}

.actions button:first-child:hover {
    background-color: var(--grey);
    color: var(--blk2);
}

.actions button:last-child {
    color: var(--danger);
}

.actions button:last-child:hover {
    background-color: var(--danger) !important;
    color: var(--light);
}

.actions .refresh-btn {
    color: var(--blk2) !important;
    background-color: var(--warning) !important;
}

.actions .refresh-btn:hover {
    color: var(--warning) !important;
    background-color: var(--blk3) !important;
}

.actions .download-all-btn {
    color: var(--light) !important;
}

.actions .download-all-btn:hover {
    background-color: var(--grey) !important;
    color: var(--blk2) !important;
}

.delete-all {
    background: transparent;
    font-weight: 600;
    font-size: .9rem;
    padding: 4px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.delete-all:hover, .actions a:last-child:hover {
    background-color: #ffdde1;
}

.form-wrapper {
    margin: auto;
    max-width: 40vw;
    min-width: 400px;
    border: 1px solid var(--secondary);
}

.form-group input, .form-group textarea {
    width: 100%;
    margin-right: 12px;
    border: 1px solid var(--secondary);
    background-color: transparent;
    color: var(--secondary);
    outline: none;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 1.1rem;
}

.form-group textarea {
    resize: none;
    height: 150px;
}

.form-group button {
    width: 100%;
}

.form-group button i {
    font-size: 1.1rem;
}

/* Loader */
.loader {
    position: relative;
    width: 100px;
    height: 16px;
}

.loader:before, .loader:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--light);
    box-shadow: 32px 0 var(--light);
    left: 0;
    top: 0;
    animation: ballMoveX 1s linear infinite;
}

.loader:after {
    box-shadow: none;
    transform-origin: 40px 0;
    transform: rotate(-153deg);
    animation: rotateLoader 1s linear infinite;
}

@keyframes rotateLoader {
    0%, 10% {
        transform: rotate(-153deg);
    }

    90%, 100% {
        transform: rotate(0deg);
    }
}

@keyframes ballMoveX {
    0%, 10% {
        transform: translateX(0)
    }

    90%, 100% {
        transform: translateX(32px)
    }
}







/* Contect Us */
.contact-us {
    position: relative;
    width: 450px;
    margin: 1.5rem 0;
    padding-top: 2.2rem;
}

.contact-us input, .contact-us textarea, .contact-us #country {
    width: 100%;
    margin-bottom: 8px;
    padding: 4px 8px;
    border: 1px solid var(--grey);
    border-radius: 3px;
    color: var(--primary);
    font-size: 1rem;
    letter-spacing: .5px;
    outline: none;
    background-color: transparent;
    transition: all .3s linear;
}

.contact-us input::placeholder, .contact-us textarea::placeholder {
    letter-spacing: 1px;
}

.contact-us input:focus, .contact-us textarea:focus, .contact-us #country:focus {
    background-color: var(--blk2);
    border: 1px solid var(--primary);
}

.contact-us textarea {
    resize: none;
    height: 180px;
}

.contact-us textarea::-webkit-scrollbar {
    display: none;
}

.contact-us #mobile {
    width: 180px;
    margin-right: 12px;
}

.contact-us #email {
    width: calc(270px - 12px);
}

.contact-us button {
    width: 100%;
}

.contact-us .err-msg {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: .9rem;
    letter-spacing: .5px;
    color: var(--danger);
    background-color: var(--blk2);
    transition: all .3s linear;
}

footer .contact-footer p {
    font-size: 1.1rem;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: .5rem;
}


footer .contact-footer .link {
    font-size: 1.5rem;
    margin: 0 10px;
    padding: 12px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: var(--primary);
    background-color: var(--blk3);
    transition: all .3s linear;
}

footer .contact-footer .link:hover {
    transform: scale(1.05);
    color: var(--blk1);
    background-color: var(--primary);
}



.active-menu {
    top: 0;
    transition: all .5s;
}





/* Error Page */
.error-wrapper {
    background: linear-gradient(to right top, var(--overlay), var(--overlay3)), url('../../public/img/source-4280758_1280.jpg');
    background-size: cover;
    background-position: center;
    min-height: 80vh;
}

.error-box {
    position: relative;
    font-size: .9rem;
    letter-spacing: .5px;
    max-width: 350px;
    min-height: 150px;
    color: var(--blk1);
    background-color: var(--grey);
}

.error-box .strip {
    height: 30px;
    border-radius: 5px 5px 0 0;
    padding: 4px 8px !important;
    background-color: var(--blk2);
}

.error-box .error-title {
    font-weight: 600;
    color: var(--light) !important;
}

.error-box .close-btn {
    font-size: 1.2rem;
    color: var(--danger);
    transition: all .3s linear;
    cursor: pointer;
}

.error-box .close-btn:hover {
    color: var(--darkorange);
}

.error-body {
    padding: 8px;
}

.error-btn {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: right;
    padding: 7px 12px;
    border-radius: 0 0 5px 5px;
    background-color: var(--blk3);
}

.error-btn a {
    margin-left: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    color: var(--light);
    transition: all .3s linear;
}

.error-btn a:hover {
    text-decoration: underline;
}

.after-error {
    position: absolute;
    font-size: 1.5rem;
    font-weight: 700;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--light);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: .5rem 1rem;
    cursor: pointer;
}