.drop-box {
    width: 100%;
    height: 125px;
    padding: 1rem 2rem;
    border-radius: .5rem;
    border: 2px dashed var(--primary);
    cursor: pointer;
    margin-top: .75rem;
}

.drop-box:hover {
    border: 1px solid var(--primary);
}
.remove-icon {
    border: 1px solid red;
    color: red;
    opacity: 0.5;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .2rem;
    cursor: pointer;
}
.remove-icon:hover {
    opacity: 1;
}
.files-box {
    width: 100%;
    height: 120px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.not-allowed {
    cursor: not-allowed;
}