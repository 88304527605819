
/* Button */

.btn {
    border: none;
    outline: none;
    padding: 5px 15px !important;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--light);
    cursor: pointer;
}
.btn-primary {
    color: var(--blk1) !important;
    background-color: var(--primary);
    transition: all .3s;
}
.btn-primary:hover {
    background: var(--blk2);
    color: var(--primary) !important;
    transition: all .3s;
}
.btn-secondary {
    background-color: var(--blk1);
    color: var(--light);
    transition: all .3s;
}
.btn-secondary:hover {
    background: var(--blk2);
    color: var(--light);
    transition: all .3s;
}
.btn-success {
    background-color: var(--success);
    color: var(--light);
    transition: all .3s;
}
.btn-success:hover {
    background-color: var(--lemongreen);
    color: var(--light);
    transition: all .3s;
}
.btn-danger {
    background-color: var(--danger);
    color: var(--light);
    transition: all .3s;
}
.btn-danger:hover {
    background-color: var(--danger);
    color: var(--blk1);
    transition: all .3s;
}